import React from 'react';
import { Link } from 'react-router-dom';

const LandingPage = () => {
  return (
    <div className="relative flex items-center justify-center min-h-screen">
      {/* Background Image */}
      <img 
        src="\707773134046953.61cc8c5399a3b.jpg" 
        alt="Ludo Game Background" 
        className="absolute inset-0 w-full h-full object-cover"
      />

      {/* Dark Overlay for Visibility */}
      <div className="absolute inset-0 bg-gradient-to-b from-black via-transparent to-black opacity-60"></div>

      {/* Sign In Button in the Top Right Corner */}
      <div className="absolute top-4 right-4 md:top-8 md:right-8 z-10">
        <Link to="/chooseLogin">
          <button className="bg-gradient-to-r from-blue-500 to-indigo-500 hover:from-blue-600 hover:to-indigo-600 text-white font-semibold py-2 px-4 md:py-3 md:px-6 rounded-lg shadow-lg transition duration-300">
            Sign In
          </button>
        </Link>
      </div>

      {/* Enhanced Overlay Content with Download Button */}
      <div className="relative z-10 text-center text-white p-6 md:p-10 rounded-lg shadow-lg bg-blue-950 bg-opacity-70 max-w-sm md:max-w-md mx-auto">
        <h1 className="text-3xl md:text-5xl font-extrabold mb-4 tracking-wide">Welcome to Ludo Game</h1>
        <p className="text-base md:text-lg mb-6 tracking-tight">Click this button below to download the game.</p>
        <div className="flex justify-center">
          <button className="bg-gradient-to-r from-green-500 to-teal-500 hover:from-green-600 hover:to-teal-600 text-white font-semibold py-2 px-4 md:py-3 md:px-6 rounded-lg shadow-lg transition duration-300">
            Download
          </button>
        </div>
      </div>
    </div>
  );
};

export default LandingPage;
