import React from 'react'
import DealerSideNav from './DealerSideNav'

const DealerAdd = () => {
  return (
    <div>
        <DealerSideNav/>
        
        
        
        </div>
  )
}

export default DealerAdd