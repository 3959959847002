import React, { useEffect, useState } from 'react';
import FournisseursSideNav from './FournisseursSideNav';
import { FaUsers, FaCoins, FaHistory } from 'react-icons/fa';
import { Line } from 'react-chartjs-2';
import 'chart.js/auto';
import axios from 'axios';
import { FaRegEdit } from "react-icons/fa";
import profileImage from '../imgs/profile.png';  // Adjust the path based on your directory structure

function FournisseursDashboard() {
  const [newMonths, setNewMonths] = useState([]);
  const [fournisseurInfo, setFournisseurInfo] = useState({});
  const [transactionsCount, setTransactionsCount] = useState(0);
  const [coins, setCoins] = useState(0);
  const [dealersCount, setDealersCount] = useState(0);
  const [isEditing, setIsEditing] = useState(false);
  const [formData, setFormData] = useState({
    name: '',
    img: '',
    phoneNumber: '',
    password:''
  });
  const [chartData, setChartData] = useState({
    labels: newMonths,
    datasets: [
      {
        label: 'Transactions Over Time',
        data: [],
        fill: false,
        borderColor: '#4f46e5',
        tension: 0.1,
      },
    ],
  });

  useEffect(() => {
    const fournisseurId = localStorage.getItem('id');
    
    if (!fournisseurId) {
      console.error('No fournisseurId found in local storage');
      return;
    }

    // Fetch fournisseur details
    axios.get(`https://ludo216.com/api/getOneFournisseur/${fournisseurId}`)
      .then(response => {
        const data = response.data;
        setFournisseurInfo(data);
        setCoins(data.coins);
        setFormData({
          name: data.name,
          img: data.img,
          phoneNumber: data.phoneNumber,
          password: data.password

        });
      })
      .catch(error => console.error('Error fetching fournisseur info:', error));

    // Fetch dealers count
    axios.get(`https://ludo216.com/api/getDealersRelatedToFournisseur/${fournisseurId}`)
      .then(response => {
        setDealersCount(response.data.length);
      })
      .catch(error => console.error('Error fetching dealers count:', error));

    // Fetch transactions count
    axios.get(`https://ludo216.com/api/transaction-history/${fournisseurId}`)
      .then(response => {
        setTransactionsCount(response.data.history.length);
      })
      .catch(error => {
        console.error('Error fetching transactions:', error);
        setTransactionsCount(0);
      });

    const fetchTransactionData = async () => {
      const months = ["Jan", "Feb", "Mar", "Apr", "May", "June", "July", "Aug", "Sept", "Oct", "Nov", "Dec"];
      let t = [], m = [];
      try {
        const response = await axios.get('https://ludo216.com/api/history/transaction');
        response.data.forEach(e => {
          t.push(e.totalTransactions);
          m.push(months[e.month - 1]);
        });
      } catch (error) {
        console.error('Error fetching transaction data:', error);
      }
      setChartData(prevChartData => ({
        ...prevChartData,
        labels: m,
        datasets: [
          {
            ...prevChartData.datasets[0],
            data: t,
          },
        ],
      }));
    };
    fetchTransactionData();
  }, []);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prev) => ({
      ...prev,
      [name]: value
    }));
  };

  const handleImageChange = (e) => {
    const file = e.target.files[0];
    if (file) {
      setFormData((prev) => ({
        ...prev,
        img: URL.createObjectURL(file)
      }));
    }
  };

  const handleSave = () => {
    axios.put(`https://ludo216.com/api/updateFournisseur/${fournisseurInfo.id}`, formData)
      .then(response => {
        setIsEditing(false); 
        setFournisseurInfo((prev) => ({
          ...prev,
          ...formData 
        }));
      })
      .catch(err => {
        console.error('Error saving fournisseur info:', err);
      });
  };

  return (
    <div className="flex flex-col min-h-screen bg-gray-100 lg:flex-row">
      {/* Sidebar */}
      <FournisseursSideNav />

      {/* Main Content */}
      <div className="flex-1 p-6">
        {/* Top Bar */}
        <div className="flex justify-between items-center mb-6">
          <h1 className="text-3xl font-semibold mt-[20px]">Fournisseur Dashboard</h1>
        </div>

        {/* Fournisseur Dashboard Cards */}
        <div className="flex flex-col lg:flex-row justify-between gap-6 mb-6">
          <div className="bg-white w-full lg:w-60 p-4 rounded-lg shadow-md flex items-center">
            <FaCoins className="text-blue-600 text-3xl mr-4" />
            <div>
              <p className="text-sm text-gray-500">Total Coins</p>
              <p className="text-xl font-semibold">{coins || 0}</p>
            </div>
          </div>
          <div className="bg-white w-full lg:w-60 p-4 rounded-lg shadow-md flex items-center">
            <FaUsers className="text-purple-600 text-3xl mr-4" />
            <div>
              <p className="text-sm text-gray-500">Total Dealers</p>
              <p className="text-xl font-semibold">{dealersCount || 0}</p>
            </div>
          </div>
          <div className="bg-white w-full lg:w-60 p-4 rounded-lg shadow-md flex items-center">
            <FaHistory className="text-red-600 text-3xl mr-4" />
            <div>
              <p className="text-sm text-gray-500">Transactions</p>
              <p className="text-xl font-semibold">{transactionsCount || 0}</p>
            </div>
          </div>
        </div>

        {/* Graph and Info */}
        <div className="grid grid-cols-1 lg:grid-cols-2 gap-6">
          {/* Transactions Over Time (Graph) */}
          <div className="bg-white p-6 rounded-lg shadow-md">
            <h2 className="text-lg font-semibold mb-4">Transactions Over Time</h2>
            <Line data={chartData} />
          </div>

          {/* Fournisseur Info */}
          <div className="bg-white p-8 rounded-lg w-full shadow-lg">
            <button
              onClick={() => setIsEditing(true)}
              className="flex items-center text-blue-600 hover:text-blue-800 mb-4"
            >
              <FaRegEdit className="mr-2" />
              Edit Info
            </button>

            <h2 className="text-2xl font-semibold text-gray-900 mb-6">Fournisseur Information</h2>

            <div className="flex flex-col md:flex-row justify-between gap-8">
            <div className="flex-1 space-y-4">
              {['UserName', 'phoneNumber', 'coins'].map((field, index) => (
                <div key={index} className="flex justify-between bg-gray-100 p-4 rounded-lg shadow-md">
                  <span className="text-sm font-semibold text-gray-700">
                    {field === 'UserName' ? 'UserName' : field.charAt(0).toUpperCase() + field.slice(1)}:
                  </span>
                  <span className="text-sm text-gray-900">
                    {field === 'UserName' ? fournisseurInfo['name'] : fournisseurInfo[field] || 'N/A'}
                  </span>
                </div>
              ))}
            </div>


              {/* Image Section */}
              <div className="flex-shrink-0 flex justify-center">
                <img
                  src={fournisseurInfo.img || profileImage}
                  alt="Fournisseur"
                  className="w-32 h-32 rounded-full object-cover"
                />
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* Edit Modal */}
      {isEditing && (
        <div className="fixed inset-0 flex justify-center items-center bg-black bg-opacity-50 z-50">
          <div className="bg-white p-6 rounded-lg w-96">
            <h2 className="text-2xl font-semibold mb-4">Edit Fournisseur Info</h2>

            {/* Form for Editing */}
            <span className="block font-bold">Username</span>
            <input
              type="text"
              name="name"
              value={formData.name}
              onChange={handleChange}
              className="w-full p-3 mb-4 rounded-lg border"
              placeholder="UserName"
            />
            <span className="block font-bold">Phone number</span>
            <input
              type="text"
              name="phoneNumber"
              value={formData.phoneNumber}
              onChange={handleChange}
              className="w-full p-3 mb-4 rounded-lg border"
              placeholder="Phone Number"
            />
            <span className="block font-bold">Password</span>
              <input
              type="password"
              name="password"
              value={formData.password}
              onChange={handleChange}
              className="w-full p-3 mb-4 rounded-lg border"
              placeholder="password"
            />
            <span className="block font-bold">Image</span>
            <input
              type="file"
              accept="image/*"
              onChange={handleImageChange}
              className="w-full mb-4 p-2"
            />

<div className="flex justify-between gap-2">
  <button
    onClick={handleSave}
    className="w-1/2 py-1.5 bg-blue-600 text-white font-semibold rounded-lg text-sm"
  >
    Save Changes
  </button>
  <button
    onClick={() => setIsEditing(false)}
    className="w-1/2 py-1.5 bg-gray-400 text-white font-semibold rounded-lg text-sm"
  >
    Cancel
  </button>
</div>


          </div>
        </div>
      )}
    </div>
  );
}

export default FournisseursDashboard;
